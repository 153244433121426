<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      hr_department_kpis: [],
      hr_employee_departments: [],
      hr_employee_transfers: [],
      hr_holiday_departments: [],

      HrDepartments: [],
      users: [],
      companies: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrDepartments: null,
      HrDepartmentsSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    addHrDepartments() {
      var data = {
        title: "popups.add",
        inputs: [
          { model: "name", type: "text", label: "name" },
          {
            model: "manager_user_id",
            type: "select",
            options: (() => {
              return this.users.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Manager",
          },
          {
            model: "acceptable_kpis_average",
            type: "text",
            label: "Acceptable Kpis Average",
          },
          { model: "descr", type: "text", label: "Description" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("hr-departments", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editHrDepartments(app) {
      if (this.companies.length <= 0) {
        this.http.post("hr-departments/departments-data", {}).then((resp) => {
          if (resp.status) {
            this.users = resp.data.users;
            this.companies = resp.data.companies;
          }
          let data = {
            title: "popups.edit",
            inputs: [
              { model: "name", type: "text", label: "name", value: app.name },

              {
                model: "manager_user_id",
                type: "select",
                options: (() => {
                  return this.users.map((data) => {
                    return { value: data.id, label: data.name };
                  });
                })(),
                label: "Manager",
              },
              {
                model: "acceptable_kpis_average",
                type: "text",
                label: "Acceptable Kpis Average",
                value: app.acceptable_kpis_average,
              },
              {
                model: "descr",
                type: "text",
                label: "Description",
                value: app.descr,
              },
            ],
            buttons: [
              {
                text: "popups.edit",
                closer: true,
                color: "primary",
                handler: (obj) => {
                  this.http.put("hr-departments", app.id, obj).then(() => {
                    this.get(this.page);
                  });
                },
              },
              {
                text: "popups.cancel",
                closer: true,
                color: "danger",
                handler: () => {
                  console.log("Like Clicked");
                },
              },
            ],
          };
          this.popup.modal(data);
        });
        return;
      }
      let data = {
        title: "popups.edit",
        inputs: [
          { model: "name", type: "text", label: "name", value: app.name },
          {
            model: "manager_user_id",
            type: "select",
            options: (() => {
              return this.users.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "Manager",
          },
          {
            model: "acceptable_kpis_average",
            type: "text",
            label: "Acceptable Kpis Average",
            value: app.acceptable_kpis_average,
          },
          {
            model: "descr",
            type: "text",
            label: "Description",
            value: app.descr,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("hr-departments", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrDepartmentsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-departments/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrDepartments = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrDepartmentsSearchMode = false;
      this.get(this.page);
    },
    getEmployees() {
      this.http.get("hr-employees").then((resp) => {
        this.users = resp.data;
      });
    },
    getHrDepartments() {
      this.http.get("hr-departments").then((res) => {
        this.HrDepartments = res.data;
      });
    },
    deleteHrDepartments(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("hr-departments", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("hr-departments/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrDepartments = res.data;
          res.data.forEach((item) => {
            this.hr_department_kpis.push(item.hr_department_kpis);
          });

          res.data.forEach((item) => {
            this.hr_employee_departments.push(item.hr_employee_departments);
          });

          res.data.forEach((item) => {
            this.hr_employee_transfers.push(item.hr_employee_transfers);
          });

          res.data.forEach((item) => {
            this.hr_employee_transfers.push(item.hr_employee_transfers);
          });

          res.data.forEach((item) => {
            this.hr_holiday_departments.push(item.hr_holiday_departments);
          });
        });
    },
    handlePagination(page) {
      this.page = page;
      this.get();
    },
  },
  created() {
    this.get(1);
    this.getEmployees();
  },
};
</script>

<template>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.departments')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="HrDepartmentsSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button
          type="button"
          @click="addHrDepartments()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("hr_departments.id") }}</th>
          <th scope="col">{{ $t("hr_departments.name") }}</th>
          <th scope="col">{{ $t("hr_departments.manager_user_id") }}</th>
          <!-- <th scope="col">{{ $t("hr_departments.descr") }}</th> -->
          <th scope="col">
            {{ $t("hr_departments.acceptable_kpis_average") }}
          </th>
          <th scope="col">{{ $t("hr_departments.created") }}</th>
          <th scope="col">{{ $t("hr_departments.updated") }}</th>
          <th scope="col">{{ $t("hr_departments.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="app in HrDepartments" :key="app" class="text-center">
          <td>{{ app?.id }}</td>
          <td>{{ app?.name }}</td>
          <td>{{ app?.manager_user?.name }}</td>
          <!-- <td>{{ app?.descr }}</td> -->
          <td>{{ app?.acceptable_kpis_average }}</td>
          <td>{{ app?.created.split("T")[0] }}</td>
          <td>{{ app?.updated.split("T")[0] }}</td>

          <td class="d-flex">
            <a
              class="btn btn-primary mx-1"
              @click="editHrDepartments(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteHrDepartments(app)"
              >{{ $t("popups.delete") }}</a
            >
            <router-link
              :to="{
                name: 'departments_profile',
                params: {
                  name: app?.name,
                  id: app?.id,
                },
              }"
            >
              <button
                class="btn btn-info mx-1"
                role="button"
                data-toggle="tooltip"
                data-placement="top"
                :title="app.name + ' ' + $t('hr_employees.profile')"
              >
                <i class="bx bxs-detail"></i>
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
